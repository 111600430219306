import React from 'react';
import './Sales.css'; // Create this CSS file for custom styles

const ExpensesHead = () => {
    return (
        <div className="sales-section">
            <h2>Recent Expenses</h2>
        </div>
    );
};

export default ExpensesHead;
