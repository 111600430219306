import React from 'react';
import './Sales.css'; // Create this CSS file for custom styles

const DealersHead = () => {
    return (
        <div className="sales-section">
            <h2>Dealers</h2>
        </div>
    );
};

export default DealersHead;
