import React from 'react';
import './Sales.css'; // Create this CSS file for custom styles

const StockAlert = () => {
    return (
        <div className="sales-section">
            <h2>Stock Alert</h2>
        </div>
    );
};

export default StockAlert;
