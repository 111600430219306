import React from 'react';
import './Sales.css'; // Create this CSS file for custom styles

const Sales = () => {
    return (
        <div className="sales-section">
            <h2>Sales</h2>
        </div>
    );
};

export default Sales;
